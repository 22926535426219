import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
  type AddressSlice,
  type MemberSlice,
  type PaymentErrorSlice,
  type ProductSlice,
  type SignupSlice,
  createAddressSlice,
  createMemberSlice,
  createPaymentErrorSlice,
  createProductSlice,
  createSignupSlice,
} from 'apps-common/store';

export type SignupStore = AddressSlice & MemberSlice & PaymentErrorSlice & ProductSlice & SignupSlice;

export const useStore = create<SignupStore>()(
  persist(
    (...a) => ({
      ...createAddressSlice(...a),
      ...createMemberSlice(...a),
      ...createPaymentErrorSlice(...a),
      ...createProductSlice(...a),
      ...createSignupSlice(...a),
    }),
    { name: 'msf', storage: createJSONStorage(() => sessionStorage) },
  ),
);
