export const LoaderIcon = ({ color = 'black' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M10.5 0C10.9832 0 11.375 0.391751 11.375 0.875V3.5C11.375 3.98325 10.9832 4.375 10.5 4.375C10.0168 4.375 9.625 3.98325 9.625 3.5V0.875C9.625 0.391751 10.0168 0 10.5 0Z"
      fill={color}
    />
    <path
      d="M0 10.5C0 10.0168 0.391751 9.625 0.875 9.625H3.5C3.98325 9.625 4.375 10.0168 4.375 10.5C4.375 10.9832 3.98325 11.375 3.5 11.375H0.875C0.391751 11.375 0 10.9832 0 10.5Z"
      fill={color}
    />
    <path
      d="M16.625 10.5C16.625 10.0168 17.0168 9.625 17.5 9.625H20.125C20.6082 9.625 21 10.0168 21 10.5C21 10.9832 20.6082 11.375 20.125 11.375H17.5C17.0168 11.375 16.625 10.9832 16.625 10.5Z"
      fill={color}
    />
    <path
      d="M10.5 16.625C10.9832 16.625 11.375 17.0168 11.375 17.5V20.125C11.375 20.6082 10.9832 21 10.5 21C10.0168 21 9.625 20.6082 9.625 20.125V17.5C9.625 17.0168 10.0168 16.625 10.5 16.625Z"
      fill={color}
    />
    <path
      d="M17.9246 4.31281C18.2663 3.9711 18.2663 3.41709 17.9246 3.07538C17.5829 2.73367 17.0289 2.73367 16.6872 3.07538L14.831 4.93153C14.4893 5.27324 14.4893 5.82726 14.831 6.16897C15.1727 6.51068 15.7268 6.51068 16.0685 6.16897L17.9246 4.31281Z"
      fill={color}
    />
    <path
      d="M4.31282 3.07538C3.97111 2.73367 3.41709 2.73367 3.07538 3.07538C2.73367 3.41709 2.73367 3.9711 3.07538 4.31281L4.93153 6.16897C5.27324 6.51068 5.82726 6.51068 6.16897 6.16897C6.51068 5.82726 6.51068 5.27324 6.16897 4.93153L4.31282 3.07538Z"
      fill={color}
    />
    <path
      d="M16.0685 14.831C15.7268 14.4893 15.1727 14.4893 14.831 14.831C14.4893 15.1727 14.4893 15.7268 14.831 16.0685L16.6872 17.9246C17.0289 18.2663 17.5829 18.2663 17.9246 17.9246C18.2663 17.5829 18.2663 17.0289 17.9246 16.6872L16.0685 14.831Z"
      fill={color}
    />
    <path
      d="M6.16897 16.0685C6.51068 15.7268 6.51068 15.1727 6.16897 14.831C5.82726 14.4893 5.27324 14.4893 4.93153 14.831L3.07538 16.6872C2.73367 17.0289 2.73367 17.5829 3.07538 17.9246C3.41709 18.2663 3.97111 18.2663 4.31282 17.9246L6.16897 16.0685Z"
      fill={color}
    />
  </svg>
);
