import { styled } from 'styled-components';

export const StyledClickToShowInputButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  width: 100%;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 18px;
  transition: all 0.5s ease;
`;
