import { styled } from 'styled-components';

interface HorizontalSeparatorProps {
  $margin?: string;
}
export const HorizontalSeparator = styled.hr<HorizontalSeparatorProps>(
  ({ theme, $margin = '20px 0' }) => `
  color: ${theme.colors.grayMedium};
  background-color: ${theme.colors.grayMedium};
  margin: ${$margin};
  border: 1px solid #32353B;
`,
);
export const VerticalSeparator = styled.hr(
  ({ theme }) => `
  color: ${theme.colors.grayMedium};
  background-color: ${theme.colors.grayMedium};
  border: 1px solid #FFF;
  transform: rotate(180deg);
  margin-left: 5px;
  margin-right: 5px;
`,
);

interface RowProps {
  $justifyContent?: string;
  $alignItems?: string;
  $padding?: string;
  $wrap?: string;
  $width?: string;
}

interface ColumnProps {
  $width?: string;
}

export const Row = styled.div<RowProps>(
  ({ $justifyContent = 'unset', $alignItems = 'unset', $padding = 'unset', $wrap = 'wrap', $width = 'unset' }) => `
  display: flex;
  flex-flow: row ${$wrap};
  padding: ${$padding};
  justify-content: ${$justifyContent};
  align-items: ${$alignItems};
  width: ${$width};
`,
);

export const Column = styled.div<ColumnProps>(
  ({ $width }) => `
  display: flex;
  flex-direction: column;
  width: ${$width};
`,
);

interface BackgroundProps {
  $marginTop?: string;
  $marginBottom?: string;
  $width?: string;
}

export const Background = styled.div<BackgroundProps>(
  ({ theme, $marginTop = '', $marginBottom = '', $width = '100%' }) => `
    background-color: ${theme.colors.grayDarkest};
    border-radius: 12px;
    margin-top: ${$marginTop};
    margin-bottom: ${$marginBottom};
    width: ${$width};
`,
);
