// Abstract class that
// - Handles changing of error.name, so that error is logged as "MyErrorClassName: my-error-message"
// - Makes sure prototype chain is correct; error extending this is also an instanceof Error
// - Sets error.stack for environments where Error itself does not implement it

export abstract class ExtendableError extends Error {
  constructor(...params: Parameters<typeof Error>) {
    super(...params);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else if (this.stack === '') {
      this.stack = new Error(...params).stack;
    }
  }
}
