import { t } from 'translations';

import { useAuth } from 'apps-common/context/auth';

import { FooterContainer } from '../../styles/containers';
import { Row } from '../../styles/layout';
import { FooterParagraph } from '../../styles/text';
import OuraTextLogo from '../assets/oura-text.svg?react';
import { HorizontalLine } from '../HorizontalLine';
import { LogoutButton } from '../LogoutButton';

import { FooterProps } from './types';
import { WebsiteLinks } from './WebsiteLinks';

export const MobileFooter = ({ onLinkClick, showLogout }: FooterProps) => {
  const { logout } = useAuth();

  return (
    <FooterContainer>
      <HorizontalLine />
      <Row $width="100%" $wrap="nowrap">
        <WebsiteLinks onLinkClick={onLinkClick} />
        {showLogout && <LogoutButton onClick={() => logout()} />}
      </Row>

      <FooterParagraph $textAlign="left" $color="grayLightest">
        <OuraTextLogo width="51px" height="16px" />
        {t('membership_signup_all_rights_reserved')}{' '}
      </FooterParagraph>
      <FooterParagraph $textAlign="left" $color="grayLightest">
        {t('membership_signup_trademarks')}{' '}
      </FooterParagraph>
    </FooterContainer>
  );
};
