import { useFormContext, useWatch } from 'react-hook-form';
import { t } from 'translations';

import { CTALoader, ErrorBanner, Form, SubmitButton, Switch } from 'ui';
import { Paragraph, SectionTitle } from 'ui/styles/text';

import { CountryWeSellTo } from '../../types';
import { logger } from '../../utils/logger';

import { AddressInputs } from './AddressInputs';

import type { FormData, FormValues } from './types';

export interface AddressFormProps {
  onSubmit?: (formData: FormData) => Promise<void>;
  onSubmitClick?: React.MouseEventHandler<HTMLButtonElement>;
  countries: CountryWeSellTo[];
  allowCountryChange: boolean;
}

export const AddressForm = ({ onSubmit, onSubmitClick, countries, allowCountryChange }: AddressFormProps) => {
  const findCountryEntity = (countryCode: string) => {
    return countries.find((c) => c.countryCode === countryCode);
  };

  const {
    clearErrors,
    control,
    formState: {
      isValid,
      isSubmitting,
      errors: { serverError },
    },
    handleSubmit,
    register,
  } = useFormContext<FormValues>();

  const [shippingAddress, billingAddress, isUnifiedAddress] = useWatch({
    control,
    name: ['shippingAddress', 'billingAddress', 'isUnifiedAddress'],
  });

  const selectedShippingCountryCode = shippingAddress.country;
  const selectedBillingCountryCode = billingAddress.country;
  const selectedShippingCountry = findCountryEntity(selectedShippingCountryCode);
  const selectedBillingCountry = findCountryEntity(selectedBillingCountryCode);

  const mustAddHomeAddress = !allowCountryChange && shippingAddress.country !== billingAddress.country;

  const hideIsUnifiedAddressToggle = mustAddHomeAddress
    ? true
    : !isUnifiedAddress && !allowCountryChange && selectedBillingCountry !== selectedShippingCountry;

  return (
    <Form
      onSubmit={handleSubmit(
        (data) => {
          // Make sure that if 'same as physical address' is selected we populate it with billingAddress copy
          if (data.isUnifiedAddress) {
            // Make sure that we populate shippingAddress with billingAddress copy if:
            // 1. 'same as physical address' is selected and
            // 2. we don't need separate home address
            if (data.isUnifiedAddress && !mustAddHomeAddress) {
              data.shippingAddress = data.billingAddress;
            }
          }
          return onSubmit?.(data);
        },
        (errors, event) => {
          // This should never happen, if seen then there is a bug in SubmitButton disabled state
          logger.error('Address submit with invalid data', { errors, event });
        },
      )}
      onFocus={() => {
        clearErrors('serverError');
      }}
    >
      {serverError && <ErrorBanner marginBottom="15px">{serverError.message}</ErrorBanner>}
      <AddressInputs
        addressType="billingAddress"
        countries={countries}
        regions={selectedBillingCountry?.regions ?? []}
        allowCountryChange={true}
      />

      <SectionTitle $margin="16px 0px" $fontSize="large">
        {t('membership_home_address')}
      </SectionTitle>

      {(mustAddHomeAddress || !isUnifiedAddress) && (
        <AddressInputs
          addressType="shippingAddress"
          countries={countries}
          regions={selectedShippingCountry?.regions ?? []}
          allowCountryChange={allowCountryChange}
        />
      )}

      {!hideIsUnifiedAddressToggle && (
        <Switch
          id="same-as-billing-address"
          data-testid="same-as-billing-address"
          {...register('isUnifiedAddress', {
            disabled: !allowCountryChange && selectedBillingCountry !== selectedShippingCountry,
          })}
          label={
            <div>
              <Paragraph>{t('membership_signup_physical_address_billing_address')}</Paragraph>
              <Paragraph $color="grayLightest">
                {t('membership_signup_billing_address_home_address_required')}
              </Paragraph>
            </div>
          }
        />
      )}

      <SubmitButton disabled={!isValid || isSubmitting} onClick={onSubmitClick} data-testid="address-submit-button">
        {isSubmitting ? <CTALoader /> : t('membership_signup_button_next')}
      </SubmitButton>
    </Form>
  );
};
