/* tslint:disable */
/* eslint-disable */
/**
 * Account API
 * Oura Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GetSignupResponse
 */
export interface GetSignupResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSignupResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignupResponse
     */
    'termsOfServiceVersion': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignupResponse
     */
    'privacyPolicyVersion': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignupResponse
     */
    'ringSerialStatus': GetSignupResponseRingSerialStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSignupResponse
     */
    'accountStatus': GetSignupResponseAccountStatusEnum;
}

export const GetSignupResponseRingSerialStatusEnum = {
    Available: 'Available',
    NotReported: 'NotReported',
    NotRegistered: 'NotRegistered',
    RegistrationFailed: 'RegistrationFailed'
} as const;

export type GetSignupResponseRingSerialStatusEnum = typeof GetSignupResponseRingSerialStatusEnum[keyof typeof GetSignupResponseRingSerialStatusEnum];
export const GetSignupResponseAccountStatusEnum = {
    New: 'New',
    InProgress: 'InProgress',
    Ready: 'Ready'
} as const;

export type GetSignupResponseAccountStatusEnum = typeof GetSignupResponseAccountStatusEnum[keyof typeof GetSignupResponseAccountStatusEnum];


