import { ReactNode, forwardRef } from 'react';

import { styled } from 'styled-components';

import { HeaderText, Paragraph, SmallText } from '../styles/text';

import { Button } from './Button';
import { ExitIcon } from './Icons';
import { CTALoader } from './Loader';

interface StyledDialogProps {
  onClose?: () => void;
}

const StyledDialog = styled.dialog<StyledDialogProps>(
  ({ onClose, theme }) => `
  background-color: ${theme.colors.grayDarkest};
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  min-width: 337px;
  max-width: 500px;
  width: 90%;
  min-height: 300px;
  padding-top: ${onClose ? '15px' : '30px'};

  &[open] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &::backdrop {
    background-color: rgb(0 0 0 / 50%);
  }
`,
);

const CloseButton = styled.button`
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  align-self: flex-end;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.div`
  padding: 15px 25px;
`;

const PrimaryButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.colors.grayDarkest};
  font-size: ${theme.fontSizes.medium};
  background: ${theme.colors.white};
`,
);

const SecondaryButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.colors.grayLightest};
  font-size: ${theme.fontSizes.small};
  line-height: 21px;
  background: ${theme.colors.grayDarkest};
  margin-top: 10px;
`,
);

interface ButtonContent {
  text: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export interface DialogProps {
  onCloseClick?: () => void;
  icon: ReactNode;
  title: ReactNode;
  textContents: ReactNode[];
  finePrint?: ReactNode[];
  primaryButton: ButtonContent;
  secondaryButton?: ButtonContent;
  children?: React.ReactNode;
}

export const Dialog = forwardRef<HTMLDialogElement, DialogProps>(
  (
    { onCloseClick, icon, title, textContents, finePrint, primaryButton, secondaryButton, children, ...dialogProps },
    ref,
  ) => {
    return (
      <StyledDialog {...dialogProps} onClose={onCloseClick} ref={ref}>
        {onCloseClick && (
          <CloseButton onClick={onCloseClick}>
            <ExitIcon />
          </CloseButton>
        )}
        {icon}
        <TextContainer>
          <HeaderText $textAlign="center" $fontSize="xxxlarge" $margin="0 0 25px">
            {title}
          </HeaderText>
          {textContents.map((text, index) => (
            <Paragraph $textAlign="center" $color="grayLightest" $lineHeight="22px" $margin="0 0 12px" key={index}>
              {text}
            </Paragraph>
          ))}
          {children}
          {finePrint && (
            <SmallText $textAlign="center" $color="grayLight" $fontSize="xsmall">
              {finePrint.map((part, index) => (
                <span key={index}>{part}</span>
              ))}
            </SmallText>
          )}
        </TextContainer>

        <ButtonContainer>
          <PrimaryButton onClick={primaryButton.onClick} disabled={!!primaryButton.disabled}>
            {primaryButton.disabled ? <CTALoader /> : primaryButton.text}
          </PrimaryButton>
          {secondaryButton && (
            <SecondaryButton onClick={secondaryButton.onClick}>
              {secondaryButton.disabled ? <CTALoader color="white" /> : secondaryButton.text}
            </SecondaryButton>
          )}
        </ButtonContainer>
      </StyledDialog>
    );
  },
);
