import { forwardRef } from 'react';

import { css, styled } from 'styled-components';

import { media } from '../../themes/signup';
import { StyledEmailIcon, StyledPasswordIcon } from '../Icons';

import { Background } from './common/Background';
import { Container } from './common/Container';
import { INPUT_PADDING } from './common/InputPadding';
import { ErrorLabel, Label } from './common/Label';

const InputElement = styled.input<{
  $hidePlaceholder?: boolean;
}>(
  ({ theme, $hidePlaceholder }) => css`
    background: unset;
    padding: 0;
    border: 0;
    outline: 0;
    ${$hidePlaceholder &&
    `
      padding-top: ${INPUT_PADDING};
      &::placeholder {
        color: transparent;
      }
    `}
    width: 100%;
    height: 48px;
    text-indent: ${INPUT_PADDING};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    line-height: 170%;

    ${media.xlarge`
      height: 60px;
      font-size: ${theme.fontSizes.medium};
    `}

    &:-webkit-autofill,
    &[data-com-onepassword-filled] {
      -webkit-text-fill-color: ${theme.colors.white};
      transition-delay: 5000s;
      transition-property: background-color, color;
    }
  `,
);

const IconContainer = styled.div`
  margin-left: ${INPUT_PADDING};
`;

// Value selected based on 1Password positioning
const ChildContainer = styled.div`
  margin-right: 18px;
`;

const getIcon = (name: string | undefined) => {
  switch (name) {
    case 'email': {
      return <StyledEmailIcon />;
    }
    case 'password': {
      return <StyledPasswordIcon />;
    }
    default: {
      return undefined;
    }
  }
};

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: 'email' | 'password'; // TODO: Support any icon, create <Icon name="some" /> API
  placeholderAsLabel?: boolean; // TODO: Should this be based on icon existing or not? When used with icon, it is completely fucked
  error?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ children, icon, error, placeholderAsLabel, ...inputProps }, ref) => {
    const { id, placeholder } = inputProps;
    const isError = !!error;
    const iconElement = getIcon(icon);

    return (
      <Container>
        <Background $isError={isError}>
          {iconElement && <IconContainer>{iconElement}</IconContainer>}
          <InputElement {...inputProps} aria-invalid={isError} $hidePlaceholder={placeholderAsLabel} ref={ref} />
          {placeholderAsLabel && <Label htmlFor={id}>{placeholder}</Label>}
          {/* Pass external stuff like close buttons as children */}
          {children && <ChildContainer>{children}</ChildContainer>}
        </Background>
        {isError && <ErrorLabel role="alert">{error}</ErrorLabel>}
      </Container>
    );
  },
);
