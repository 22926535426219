import { css, styled } from 'styled-components';

export const Background = styled.div<{
  $isError?: boolean;
}>(
  ({ theme, $isError }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    background: ${theme.colors.background};
    width: 100%;
    border-radius: 12px;
    outline: 1px solid ${$isError ? theme.colors.errorText : theme.colors.grayMedium};

    &:hover {
      outline-width: 2px;
    }

    &:focus-within {
      outline-color: ${$isError ? theme.colors.errorText : theme.colors.helsinkiBlue};
    }

    &:has(select:disabled),
    &:has(input:disabled) {
      background: ${theme.colors.disabledBackground};

      &:hover {
        outline-width: 1px;
      }
    }
  `,
);
