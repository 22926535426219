/*
  This page handles Paypal payment methods after customer approves the transaction/agreement on external Paypal page.
  The customer returns with QueryParameters:
  {
    token: string
  }
  which will be used to:
  1. Create Billing Agreement ID
  2. Fetch customer info from Paypal
  3. And send the customer email + BAID to Zuora for creating a new payment method.
  4. Redirect customer to management page
*/

import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';

import { Loader } from 'ui';

import {
  CreatePaypalPaymentMethodInput,
  CreatePaypalPaymentMethodResponse,
  paypalPaymentMethod,
} from '../../graphql/paypalPaymentMethod';
import { useQueryParams } from '../../hooks/useQueryParams';
import { FlowType } from '../../types';
import { logger } from '../../utils/logger';

import { CallbackError } from './CallbackError';

import type { OnErrorCallback, OnSuccessCallback } from './index';

const usePaypalPaymentMethod = () =>
  useMutation<CreatePaypalPaymentMethodResponse['paypalPaymentMethod'], Error, CreatePaypalPaymentMethodInput>({
    mutationKey: ['paypalCallback'],
    mutationFn: async (vars) => {
      const response = await paypalPaymentMethod(vars);
      return response.paypalPaymentMethod;
    },
  });

const useHandleCallback = ({ isUnitedStates, paymentGateway, onSuccess, onError }: PaypalCallbackPageProps) => {
  const queryParams = useQueryParams();
  const sessionToken = queryParams.get('token');
  const flow = queryParams.get('renew') ? FlowType.renew : FlowType.normal;
  const { mutate } = usePaypalPaymentMethod();

  useEffect(() => {
    if (sessionToken) {
      mutate(
        {
          token: sessionToken,
          isUnitedStates,
          paymentGatewayName: paymentGateway,
        },
        {
          onSuccess: (resp) => {
            onSuccess(resp.paymentMethodId, flow);
          },
          onError: (error) => {
            const msg = 'Error occurred when creating Paypal payment method';
            logger.error(msg, {
              error,
            });
            onError(
              new CallbackError(msg, 'PAYPAL_PAYMENT_METHOD_FAILED', {
                cause: error,
              }),
            );
          },
        },
      );
    }
  }, [isUnitedStates, paymentGateway, sessionToken, flow, mutate, onSuccess, onError]);
};

interface PaypalCallbackPageProps {
  onSuccess: OnSuccessCallback;
  onError: OnErrorCallback;
  isUnitedStates: boolean;
  paymentGateway: string;
}

export const PaypalCallbackPage = (props: PaypalCallbackPageProps) => {
  useHandleCallback(props);
  const queryParams = useQueryParams();
  const sessionToken = queryParams.get('token');

  if (!sessionToken) {
    props.onError(new CallbackError('Paypal token missing from query parameters', 'MISSING_PAYPAL_TOKEN'));
    return null;
  }

  return <Loader />;
};
