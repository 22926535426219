import { useMutation } from '@tanstack/react-query';

import { PaymentSignatureInput, paymentSignature } from '../graphql/paymentSignature';
import { PaymentSignature } from '../types';

export const useCreatePaymentSignature = () =>
  useMutation<PaymentSignature, Error, PaymentSignatureInput>({
    mutationFn: async (vars) => {
      const response = await paymentSignature(vars);
      return response.paymentSignature;
    },
  });
