import pick from 'lodash/pick';

import { Address, AddressValidationContact, Contact, CountryWeSellTo, Region } from '../types';

import { removeEmptyStringOrNilProperties } from './helpers';

interface HasLabel {
  label: string;
}

export const convertContactToAddress = (contact: Contact): Address => {
  return pick(removeEmptyStringOrNilProperties(contact) as Contact, [
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'state',
    'postalCode',
    'country',
  ]);
};

export const convertAddressToContactForValidation = (address: Address, personalEmail: string) => {
  const shipToContact: AddressValidationContact = {
    ...address,
    personalEmail,
  };
  return shipToContact;
};

export const convertStateTo2CharStateCode = (
  stateName: string | undefined,
  country: CountryWeSellTo | undefined,
): string | undefined => {
  const state = country?.regions.find((region: Region) => region.label === stateName);
  return state?.value ?? stateName;
};

export const convertAddressStateNameTo2CharCode = (addressData: Address, countries: CountryWeSellTo[]): Address => {
  if (!addressData.state) return addressData;
  return {
    ...addressData,
    state: convertStateTo2CharStateCode(
      addressData.state,
      countries.find((c) => c.countryCode === addressData.country),
    ),
  };
};
export const postGridEnabledCountries = ['US', 'CA'];

/**
 * Sorts an array of objects by the 'label' property alphabetically.
 * @param array - Array of objects with a 'label' property.
 * @returns The sorted array.
 */
export function sortByLabel<T extends HasLabel>(array: T[]): T[] {
  return array.sort((a, b) => a.label.localeCompare(b.label));
}
export const streetAddressContainsRestrictedCharacters = (streetAddress: string) => streetAddress.includes('\\');
