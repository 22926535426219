import { styled } from 'styled-components';

type IconButtonProps = {
  name: string;
  icon: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const StyledIconButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  background-color: unset;
  cursor: pointer;
  display: flex;
`;

export const IconButton = ({ name, icon, ...props }: IconButtonProps) => {
  return (
    <StyledIconButton aria-label={name} {...props}>
      {icon}
    </StyledIconButton>
  );
};
