import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports
import { useStore } from 'membership-management-portal/src/store';

import { getAccount } from '../graphql/getAccount';

export const useGetAccount = () => {
  const accountId = useStore((state) => state.accountId);
  return useQuery({
    queryKey: ['getPaymentMethods', accountId],
    queryFn: getAccount,
    retry: false,
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};
