import { PropsWithChildren, useEffect, useState } from 'react';

import axios, { type AxiosInstance } from 'axios';

interface AxiosInterceptorProps extends PropsWithChildren {
  resInterceptor?: Parameters<AxiosInstance['interceptors']['response']['use']>['0'];
  errResInterceptor?: Parameters<AxiosInstance['interceptors']['response']['use']>['1'];
}

export const AxiosInterceptor = ({ children, resInterceptor, errResInterceptor }: AxiosInterceptorProps) => {
  // https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h#comment-1pm76
  const [isInterceptorsSet, setIsInterceptorsSet] = useState(false);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(resInterceptor, errResInterceptor);
    setIsInterceptorsSet(true);

    return () => axios.interceptors.response.eject(interceptor);
  }, [resInterceptor, errResInterceptor]);

  return isInterceptorsSet && children;
};
