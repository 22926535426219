import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations';

import { PaymentDisclaimer } from 'apps-common/components/PaymentDisclaimer';
import { ProductSelector } from 'apps-common/components/ProductSelector';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { BillingPeriod } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { getRatePlansForCountry } from 'apps-common/utils/getProduct';
import { logger } from 'apps-common/utils/logger';
import { Form, Header, Loader, SubmitButton } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

export const ProductPage = () => {
  const { data: offerings, isFetching } = useGetMembershipOfferings();
  const allProducts = offerings?.products;

  const navigate = useNavigate();
  const userAddressForm = useStore((state) => state.userAddressForm!);

  const prepaidMonths = offerings?.prepaidMonths ?? 0;
  const isPrepaid = prepaidMonths > 0;

  const setRatePlan = useStore((state) => state.setRatePlan);
  const signupInfo = useStore((state) => state.signupInfo);

  const ratePlans = getRatePlansForCountry(allProducts ?? [], userAddressForm.shippingAddress.country);
  const { formState, handleSubmit, register } = useForm<{
    product: BillingPeriod;
  }>({
    values: {
      product: ratePlans[0]?.billingPeriod,
    },
  });

  const TITLE = t('membership_hub_ongoing_plan');
  const SUBTITLE = isPrepaid
    ? t('membership_hub_choose_ongoing_plan_after_prepaid', {
        months: prepaidMonths,
      })
    : t('membership_hub_signup_product_subtitle_normal_flow');
  const BANNER_TEXT = getSignupBannerText(isPrepaid, prepaidMonths);

  const onSubmit = handleSubmit((data) => {
    const { product: billingPeriod } = data;
    const ratePlan = ratePlans.find((p) => p.billingPeriod === billingPeriod)!;

    setRatePlan(ratePlan);
    track({
      event: 'Membership Plan Selected',
      payload: {
        productType: ratePlan.billingPeriod,
      },
    });

    logger.info('Plan selected, continuing to payment method page');
    navigate(routes.paymentMethod);
  });

  return (
    <>
      <Header
        appType="signup"
        pageType="create"
        title={TITLE}
        subTitle={SUBTITLE}
        bannerText={BANNER_TEXT}
        testId="membership-plan-header"
        steps={{ current: 2, total: 3 }}
        onBackClick={() => navigate(routes.address)}
        ringSerialStatus={signupInfo.ringSerialStatus}
      />
      <MainContainer>
        {isFetching && <Loader />}
        {!isFetching && (
          <Form onSubmit={onSubmit}>
            <ProductSelector ratePlans={ratePlans} {...register('product', { required: true })} />
            <SubmitButton disabled={!formState.isValid} data-testid="membership-plan-next-button">
              {t('membership_signup_button_next')}
            </SubmitButton>
            <PaymentDisclaimer />
          </Form>
        )}
      </MainContainer>
    </>
  );
};
