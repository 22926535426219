import { forwardRef } from 'react';

import { t } from 'translations';

import { RadioGroup, RadioGroupOption, RadioGroupOptionProps } from 'ui';
import { SmallText } from 'ui/styles/text';

import { RatePlan } from '../types';
import {
  periodToProductNameKey,
  periodToProductPlanKey,
  periodToProductPlanNameKey,
} from '../utils/productLocalization';

interface ProductSelectorProps extends Omit<RadioGroupOptionProps, 'labelText'> {
  ratePlans: RatePlan[];
}

export const ProductSelector = forwardRef<HTMLInputElement, ProductSelectorProps>(
  ({ ratePlans, ...radioProps }, ref) => (
    <RadioGroup>
      {ratePlans.map(({ billingPeriod, amount, currency }) => {
        const price = `${amount.toLocaleString(undefined, {
          style: 'currency',
          currency: currency,
        })} ${currency}`;

        const priceText = t(periodToProductPlanKey(billingPeriod), {
          price: price,
        });

        const labelText = (
          <div>
            <SmallText $color="white2" $fontSize="medium" $lineHeight="140%">
              {t(periodToProductPlanNameKey(billingPeriod))}
            </SmallText>
            <SmallText $color="lightGreen" $fontSize="small" $lineHeight="150%">
              {priceText}
            </SmallText>
          </div>
        );
        return (
          <RadioGroupOption
            {...radioProps}
            ref={ref}
            key={billingPeriod}
            value={billingPeriod}
            labelText={labelText}
            data-testid={periodToProductNameKey(billingPeriod)}
          />
        );
      })}
    </RadioGroup>
  ),
);
