import { useQuery } from '@tanstack/react-query';

import { countriesWeSellMembership } from '../graphql/countriesWeSellMembership';
import { CountryWeSellTo } from '../types';

export const useGetSellToCountries = (isEnabled = true) =>
  useQuery<CountryWeSellTo[]>({
    queryKey: ['countriesWeSellMembership'],
    queryFn: async () => {
      const response = await countriesWeSellMembership();
      return response.countriesWeSellMembership;
    },
    retry: false,
    staleTime: Infinity,
    refetchOnMount: true, // always returns cached data. This is to avoid unnecessary network calls as this data is static
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
