import { gql } from 'graphql-request';

import { axiosQuery } from '../utils/axiosQuery';

export interface CreatePartnershipSubscriptionResponse {
  createPartnershipSubscription: {
    subscriptionId?: string;
    message?: string;
  };
}

const CREATE_PARTNERSHIP_SUBSCRIPTION_MUTATION = gql`
  mutation CreatePartnershipSubscription {
    createPartnershipSubscription {
      ... on ApiCreateSubscriptionSuccess {
        subscriptionId
      }
      ... on ApiPaymentGatewayFailedResponse {
        message
      }
    }
  }
`;

export const createPartnershipSubscription = () =>
  axiosQuery<CreatePartnershipSubscriptionResponse>(
    'CreatePartnershipSubscription',
    CREATE_PARTNERSHIP_SUBSCRIPTION_MUTATION,
  );
