import { gql } from 'graphql-request';

import { axiosQuery } from '../utils/axiosQuery';

export interface CreatePaypalPaymentMethodInput {
  isUnitedStates: boolean;
  paymentGatewayName: string;
  token: string;
}

export interface CreatePaypalPaymentMethodResponse {
  paypalPaymentMethod: {
    paymentMethodId: string;
  };
}

const PAYPAL_PAYMENT_METHOD_MUTATION = gql`
  mutation PaypalPaymentMethod($token: String!, $isUnitedStates: Boolean!, $paymentGatewayName: String!) {
    paypalPaymentMethod(token: $token, isUnitedStates: $isUnitedStates, paymentGatewayName: $paymentGatewayName) {
      paymentMethodId
    }
  }
`;

export const paypalPaymentMethod = (vars: CreatePaypalPaymentMethodInput) =>
  axiosQuery<CreatePaypalPaymentMethodResponse>('PaypalPaymentMethod', PAYPAL_PAYMENT_METHOD_MUTATION, vars);
