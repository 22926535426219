import { ReactNode } from 'react';

import { styled } from 'styled-components';
import { t } from 'translations';

import { Address, Problem } from 'apps-common/types';
import { SectionTitle, SmallText } from 'ui/styles/text';

const Container = styled.div(
  ({ theme }) => `
    border: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
      border: none;
      background-color: ${theme.colors.grayDark};
      border-radius: 12px;
      padding: 20px;
    }

    & > div:last-child {
      border-radius: 12px;
    }
`,
);

const LabelText = styled(SmallText)(
  ({ theme }) => `
  gap: 8px;
  color: ${theme.colors.white2};
  & > span {
    color: ${theme.colors.lightRed};
  }
`,
);

const highlightErrors = (problems: Problem[] = [], address?: Address) => {
  if (!address) {
    return null;
  }
  const { firstName, lastName, country, ...restOfAddress } = address;
  const highlightedAddress: Record<string, ReactNode> = {};

  Object.keys(restOfAddress).forEach((key) => {
    const value = address[key as keyof Address];
    if (problems.find((problem) => problem.field === key)) {
      highlightedAddress[key] = <span key={key}>{value}</span>;
    } else highlightedAddress[key] = value;
  });
  return highlightedAddress;
};

interface AddressSuggestionResultsProps {
  currentAddress: Address;
  correctedAddress: Address;
  problems: Problem[];
}

export const AddressSuggestionResults = ({
  currentAddress,
  correctedAddress,
  problems,
}: AddressSuggestionResultsProps) => {
  const errorAddress = highlightErrors(problems, currentAddress);
  return (
    <Container>
      <div data-testid="suggested">
        <SectionTitle $margin="0 0 8px">
          {t('membership_hub_address_validation_suggestion_suggested_address_header')}
        </SectionTitle>
        <LabelText>
          {correctedAddress.address1}
          {', '}
          {correctedAddress.city + ', ' + correctedAddress.state + ', ' + correctedAddress.postalCode}
        </LabelText>
      </div>

      <div data-testid="entered">
        <SectionTitle $margin="0 0 8px">
          {t('membership_hub_address_validation_suggestion_entered_address_header')}
        </SectionTitle>
        <LabelText>
          {' '}
          {errorAddress?.address1}
          {', '}
          {errorAddress?.city}
          {', '}
          {errorAddress?.state}
          {', '}
          {errorAddress?.postalCode}
        </LabelText>
      </div>
    </Container>
  );
};
