import { gql } from 'graphql-request';

import { CountryWeSellTo } from '../types';
import { axiosQuery } from '../utils/axiosQuery';

interface CountriesWeSellToResponse {
  countriesWeSellMembership: CountryWeSellTo[];
}

const SELL_MEMBERSHIP_TO_COUNTRIES_QUERY = gql`
  query CountriesWeSellMembership {
    countriesWeSellMembership {
      countryCode
      isoAlpha3Code
      name
      regions {
        label
        value
      }
    }
  }
`;

export const countriesWeSellMembership = () =>
  axiosQuery<CountriesWeSellToResponse>('CountriesWeSellMembership', SELL_MEMBERSHIP_TO_COUNTRIES_QUERY);
