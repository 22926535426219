import { gql } from 'graphql-request';

import { MembershipOfferings } from '../types';
import { axiosQuery } from '../utils/axiosQuery';

const MEMBERSHIP_OFFERINGS_QUERY = gql`
  query MembershipOfferings {
    membershipOffering {
      membershipStatus {
        type
        subscriptionFound
      }
      channel
      entitlement {
        type
      }
      prepaidMonths
      eligibleForPartnership
      customerId
      customerName
      products {
        name
        channel
        trial {
          length
          periodType
        }
        membership {
          periodType
          length
        }
        taxMode
        countries {
          code
          pricing {
            currency
            amount
          }
        }
      }
    }
  }
`;

interface MembershipOfferingsResponse {
  membershipOffering: MembershipOfferings;
}

export const membershipOfferings = () =>
  axiosQuery<MembershipOfferingsResponse>('MembershipOfferings', MEMBERSHIP_OFFERINGS_QUERY);
