import { keyframes, styled } from 'styled-components';

import { Paragraph } from '../styles/text';

import { LoaderIcon } from './Icons/LoaderIcon';

interface ContainerProp {
  $isWholePage: boolean;
}
const Container = styled.div<ContainerProp>(
  ({ $isWholePage }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: ${$isWholePage ? '100vh' : '100%'};
`,
);

const LoaderTop = styled.div(
  ({ theme }) => `
  width: 1.8125rem;
  border: 4px solid #FFF;
  border-color: ${theme.colors.grayLight};
  margin-bottom: 0.6rem;

  @keyframes rotation {
    0% {
      width: 0;
    }

    50% {
      width: 100%;
    }

    100% {
      width: 0;
    }
  }
`,
);

const StyledLoader = styled.div(
  ({ theme }) => `
  border-width: 0.5rem;
  border-style: solid;
  border-color: ${theme.colors.grayLight} ${theme.colors.grayLight} ${theme.colors.grayLight} #ADB1BB;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 50%;
  position: relative;
  animation: spin 1s infinite;

  &::before,
  &::after {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #ADB1BB;
    position: absolute;
  }

  &::before {
      top: 0.0;
  }

  &::after {
    bottom: 0.0;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`,
);

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const StyledCTALoader = styled.div`
  display: inline-flex;
  animation: ${spin} 1s linear infinite;
  transform-origin: center center;
`;

export const CTALoader = ({ color = 'black' }: { color?: string }) => {
  return (
    <StyledCTALoader title="loading">
      <LoaderIcon color={color} />
    </StyledCTALoader>
  );
};

interface LoaderProp {
  isWholePage?: boolean;
  text?: string;
}

export const Loader = ({ isWholePage = true, text }: LoaderProp) => (
  <Container $isWholePage={isWholePage}>
    <LoaderTop />
    <StyledLoader />
    {text && <Paragraph $margin="15px 0 0 0">{text}</Paragraph>}
  </Container>
);
