import { forwardRef } from 'react';

import { css, styled } from 'styled-components';

import { media } from '../../themes/signup';

import { Background } from './common/Background';
import { Container } from './common/Container';
import { INPUT_PADDING } from './common/InputPadding';
import { ErrorLabel, Label } from './common/Label';

export interface DropdownOption {
  value: string;
  label: string;
}

const Select = styled.select(
  ({ theme }) => css`
    appearance: none;
    background: unset;
    padding: 0;
    border: 0;
    outline: 0;

    option {
      padding: 0;
      color: black;
      background: ${theme.colors.white};
    }

    padding-top: ${INPUT_PADDING};
    width: 100%;
    height: 48px;
    text-indent: ${INPUT_PADDING};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    line-height: 170%;

    ${media.xlarge`
      height: 60px;
      font-size: ${theme.fontSizes.medium};
    `}
  `,
);

interface DropwDownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: DropdownOption[];
  label: string;
  firstOptionEmpty?: boolean;
  error?: string;
}

export const DropDown = forwardRef<HTMLSelectElement, DropwDownProps>(
  ({ options, label, error, firstOptionEmpty, ...selectProps }, ref) => {
    const { id, value = '' } = selectProps;
    const isError = !!error;
    return (
      <Container>
        <Background $isError={isError}>
          <Select
            {...selectProps}
            role="combobox"
            multiple={false}
            required
            value={value}
            aria-invalid={isError}
            ref={ref}
          >
            {firstOptionEmpty && <option key="placeholder" value="" hidden />}
            {options.map((op) => {
              return (
                <option key={op.value} value={op.value}>
                  {op.label}
                </option>
              );
            })}
          </Select>
          <Label htmlFor={id}>{label}</Label>
        </Background>
        {isError && <ErrorLabel role="alert">{error}</ErrorLabel>}
      </Container>
    );
  },
);
