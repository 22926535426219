import { gql } from 'graphql-request';

import { AddressValidationContact, Problem } from '../types';
import { axiosQuery } from '../utils/axiosQuery';
import { throwError } from '../utils/errorHandler';
import { removeEmptyStringOrNilProperties } from '../utils/helpers';

interface AddressValidation {
  success: boolean;
  problems: Problem[];
}

interface AddressValidationResponse {
  validateAddress: AddressValidation;
}

const VALIDATE_ADDRESS_QUERY = gql`
  query ValidateAddress($shipToContact: ApiContactInput!) {
    validateAddress(shipToContact: $shipToContact) {
      success
      problems {
        field
        message
        correctedValue
      }
    }
  }
`;

export const validateAddress = async (shippingAddress: AddressValidationContact): Promise<AddressValidation> => {
  try {
    const shipToContactInput = removeEmptyStringOrNilProperties(shippingAddress);

    const res = await axiosQuery<AddressValidationResponse>('ValidateAddress', VALIDATE_ADDRESS_QUERY, {
      shipToContact: shipToContactInput,
    });
    return res.validateAddress;
  } catch (e) {
    throwError('errorOnAddressValidation', e);
  }
};
