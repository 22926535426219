import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations';

import { AddressForm, AddressFormProps } from 'apps-common/components/AddressForm';
import { FormValues, emptyForm } from 'apps-common/components/AddressForm/types';
import { useAddressTaxValidation } from 'apps-common/components/AddressForm/useAddressTaxValidation';
import { AddressSuggestionModal } from 'apps-common/components/AddressSuggestionModal';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { useGetSellToCountries } from 'apps-common/hooks/useSellToCountries';
import { Address, BillingPeriod, Problem } from 'apps-common/types';
import { convertAddressToContactForValidation, postGridEnabledCountries } from 'apps-common/utils/address';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { getRatePlan } from 'apps-common/utils/getProduct';
import { isEntitlementUser, removeEmptyStringOrNilProperties } from 'apps-common/utils/helpers';
import { logger } from 'apps-common/utils/logger';
import { Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

export const useAccessControl = () => {
  const userTypeFromSession = useStore((state) => state.userType);
  if (!userTypeFromSession) {
    throwError('missingUserTypeFromOffering');
  }

  return {
    isAccessAllowed: !isEntitlementUser(userTypeFromSession),
  };
};

const useRedirectToCompletePage = () => {
  const navigate = useNavigate();
  const { isAccessAllowed } = useAccessControl();
  const [initialAccess] = useState(isAccessAllowed);

  useEffect(() => {
    if (!initialAccess) {
      navigate(routes.complete);
    }
  }, [initialAccess, navigate]);
};

export const SignUpAddress = () => {
  const navigate = useNavigate();
  useRedirectToCompletePage();
  const { isFetching: isFetchingOffers, data } = useGetMembershipOfferings();
  const setRatePlan = useStore((state) => state.setRatePlan);
  const email = useStore((state) => state.signupInfo.email); // TODO: This is needed for address validation. Maybe we could remove the requirement from API?
  const signupInfo = useStore((state) => state.signupInfo);
  const [showAddressSuggestionModal, setShowAddressSuggestionModal] = useState(false);
  const [problems, setProblems] = useState<Problem[]>([]);
  const steps = { current: 1, total: 3 };

  const setUserAddressForm = useStore((state) => state.setUserAddressForm);
  const setBillingCountryIsoAlpha3Code = useStore((state) => state.setBillingCountryIsoAlpha3Code);
  const addressvalidationSuggestionsFlag = useFlag(Flags.ADDRESS_VALIDATION_SUGGESTIONS);

  const nextPage = routes.product;

  const {
    data: countryData,
    isFetching: isFetchingCountries,
    isError: isErrorGetCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!data);

  const userAddressForm = useStore((state) => state.userAddressForm);
  const formMethods = useForm<FormValues>({
    defaultValues: userAddressForm ?? emptyForm,
    criteriaMode: 'all',
    mode: 'onTouched',
  });
  const { mutateAsync: addressValidationMutate } = useAddressTaxValidation(formMethods);

  const prepaidMonths = data?.prepaidMonths ?? 0;
  const isPrepaid = prepaidMonths > 0;

  const TITLE = t('signup_account_setup_title');
  const SUBTITLE = isPrepaid ? t('signup_account_setup_prepaid_subtitle') : t('signup_account_setup_default_subtitle');
  const BANNER_TEXT = getSignupBannerText(isPrepaid, prepaidMonths);

  const header = (
    <Header
      appType="signup"
      pageType="create"
      bannerText={BANNER_TEXT}
      title={TITLE}
      subTitle={SUBTITLE}
      steps={steps}
      testId="signup-address-header"
      ringSerialStatus={signupInfo.ringSerialStatus}
    />
  );

  if (isFetchingOffers || isFetchingCountries) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (!data) {
    throwError('errorOnGetAccount', 'membership offerings not found');
  }

  if (isErrorGetCountries || !countryData) {
    throwError('errorOnGettingCountries', errorInGetCountries);
  }

  const selectProduct = (country: string) => {
    const ratePlan = getRatePlan(data.products, country, BillingPeriod.Years);
    if (ratePlan) {
      setRatePlan(ratePlan);
    }
  };

  const onSubmit: AddressFormProps['onSubmit'] = async (data) => {
    setBillingCountryIsoAlpha3Code(data.billingAddress.isoAlpha3Code ?? '');
    setUserAddressForm({
      shippingAddress: removeEmptyStringOrNilProperties(data.shippingAddress) as Address,
      billingAddress: removeEmptyStringOrNilProperties(data.billingAddress) as Address,
    });

    const userInputHomeAddressToValidate = convertAddressToContactForValidation(data.shippingAddress, email);
    // Set product and navigate to next page if tax validation is success
    const onValidationSuccess = () => {
      selectProduct(data.shippingAddress.country);
      track({
        event: 'Address Added',
      });

      logger.info('Address added, continuing to next page', { nextPage });
      navigate(nextPage);
    };

    // Always use shipping address for address validation for tax calculation
    await addressValidationMutate(userInputHomeAddressToValidate, {
      onSuccess: ({ success, problems }) => {
        if (success) {
          onValidationSuccess();
        } else {
          if (
            postGridEnabledCountries.includes(userInputHomeAddressToValidate.country) &&
            problems.some((problem) => problem.correctedValue) &&
            addressvalidationSuggestionsFlag
          ) {
            setShowAddressSuggestionModal(true);
          }
          setProblems(problems);
          const filteredProblems = problems.map(({ correctedValue, ...rest }) => rest);
          logger.warn('Address Validation Failed', {
            problems: filteredProblems,
            country: userInputHomeAddressToValidate.country,
          });
          // Track address validation failures
          track({
            event: 'Address Validation Failed',
            payload: {
              reason: 'INVALID_ADDRESS',
              problems: filteredProblems,
              country: userInputHomeAddressToValidate.country,
            },
          });
        }
      },
    });
  };

  return (
    <>
      {showAddressSuggestionModal && formMethods.getValues('shippingAddress') && (
        <AddressSuggestionModal
          showModal={showAddressSuggestionModal}
          setShowModal={setShowAddressSuggestionModal}
          isUnifiedAddress={formMethods.getValues('isUnifiedAddress')}
          currentAddress={
            // Note: shippingAddress is not yet copied to shippingAddress at this point
            formMethods.getValues('isUnifiedAddress')
              ? formMethods.getValues('billingAddress')
              : formMethods.getValues('shippingAddress')
          }
          setAddressFormData={formMethods.setValue}
          problems={problems}
          clearErrors={formMethods.clearErrors}
        />
      )}
      {header}
      <MainContainer>
        <FormProvider {...formMethods}>
          <AddressForm
            onSubmit={onSubmit}
            onSubmitClick={() => {
              track({
                event: 'CTA Clicked',
                payload: { cta: 'next', action: 'onboarding_next_step' },
              });
            }}
            countries={countryData}
            allowCountryChange={true}
          />
        </FormProvider>
      </MainContainer>
    </>
  );
};
