import { useMutation } from '@tanstack/react-query';

import {
  CreateEntitlementSubscriptionResponse,
  createEntitlementSubscription,
} from '../graphql/createEntitlementSubscription';
import { throwError } from '../utils/errorHandler';
import { isSupaGQLError } from '../utils/SupaGraphQLError';

const handleSubCreateError = (error: unknown): CreateEntitlementSubscriptionResponse => {
  if (isSupaGQLError(error)) {
    if (error.code === 'SUBSCRIPTION_ALREADY_EXISTS') {
      return {};
    }
    if (error.code === 'EMAIL_ADDRESS_REQUIRED' || error.code === 'ENTITLEMENT_REQUIRED') {
      throwError(error.message, error);
    }
  }

  throw error;
};

export const useCreateEntitlementSubscription = () =>
  useMutation({
    mutationKey: ['createEntitlementSubscription'],
    mutationFn: () => createEntitlementSubscription().catch(handleSubCreateError),
  });
