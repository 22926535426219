import { gql } from 'graphql-request';

import { axiosQuery } from '../utils/axiosQuery';

// Hidden since not used, and we would need to figure out which id to return in case of SUB_EXISTS
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface CreateEntitlementSubscriptionResponse {
  // id: string;
}

const CREATE_ENTITLEMENT_SUBSCRIPTION_MUTATION = gql`
  mutation CreateEntitlementSubscription {
    createEntitlementSubscription {
      subscriptionId
    }
  }
`;

export const createEntitlementSubscription = () =>
  axiosQuery<CreateEntitlementSubscriptionResponse>(
    'CreateEntitlementSubscription',
    CREATE_ENTITLEMENT_SUBSCRIPTION_MUTATION,
  );
