import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import OuraTextLogo from '../assets/oura-text.svg?react';

interface OuraHeaderLogoProps {
  app: 'hub' | 'signup';
  height?: string;
  width?: string;
}

export const OuraHeaderLogo = ({ app, height = '19px', width = '60px' }: OuraHeaderLogoProps) => {
  const intl = useIntl();
  const hubHomePage = '/';

  if (app === 'hub') {
    return (
      <Link
        to={hubHomePage}
        aria-label={intl.formatMessage({ id: 'membership_hub_accessibility_navigate_to' }, { link: 'home' })}
      >
        <OuraTextLogo height={height} width={width} flex-shrink="0" />
      </Link>
    );
  }
  return <OuraTextLogo height={height} width={width} flex-shrink="0" />;
};
