import { styled } from 'styled-components';
import { t } from 'translations';

import { Background } from 'ui/styles/layout';
import { Link, Paragraph } from 'ui/styles/text';

import Logo from './assets/hsa_eligility.svg?react';

const Bg = styled(Background)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

export const HSAEligibilityBanner = () => (
  <Bg>
    <Logo />
    <Paragraph $fontSize="small" $lineHeight="140%" $color="white">
      {t('hsa_fsa_banner_body_text', {
        link: (
          <Link
            $fontSize="14px"
            $color="white"
            target="_blank"
            href="https://support.ouraring.com/hc/en-us/articles/19777137314579-Purchase-with-HSA-or-FSA-Funds"
          >
            {t('hsa_fsa_banner_link_text')}
          </Link>
        ),
      })}
    </Paragraph>
  </Bg>
);
