import * as z from 'zod';

export const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,250}$/);
/** Type for password for new accounts and updated passwords */
export const passwordType = z.string().regex(passwordRegex);

export const zipCodeRegex = /^[- 0-9a-zA-Z]+$/i;
export const zipCodeType = z.string().regex(zipCodeRegex);

// TODO: use email from Zod to validate
export const emailRegex = /\S+@\S+\.\S+/;
export const emailType = z.string().email();
