import { css, styled } from 'styled-components';

import { media } from '../../themes/signup';

export type AppType = 'signup' | 'hub';
export type PageType = 'landing' | 'hubHome' | 'error' | 'create' | 'complete';
export interface Step {
  current: number;
  total: number;
}
interface StyledBaseHeaderProps {
  $padding?: string;
  $marginTop?: string;
  $marginBottom?: string;
  $alignItems?: string;
}
interface StyledRingImageTitleHeaderContainerProps {
  $padding?: string;
  $flexDirection?: string;
  $isLoginPage?: boolean;
}

export const StyledBaseHeader = styled.header<StyledBaseHeaderProps>(
  ({ $marginTop = '20px', $marginBottom = '20px', $alignItems = 'flex-start', $padding = '0' }) => css`
    margin-top: ${$marginTop};
    margin-bottom: ${$marginBottom};
    display: flex;
    flex-direction: column;
    align-items: ${$alignItems};
    position: relative;
    padding: ${$padding};

    ${media.small`
        margin-top: ${$marginTop};
     `}
  `,
);

// used for lading, login and complete pages where ring image and titles(+ subtitles) are displayed
export const StyledRingImageTitleHeaderContainer = styled.div<StyledRingImageTitleHeaderContainerProps>(
  ({ $padding = '0', $flexDirection = 'column', $isLoginPage = false }) => css`
    padding: ${$padding};
    text-align: center;
    display: flex;
    flex-direction: ${$flexDirection};
    align-items: center;
    align-self: center;
    width: 100%;

    ${media.xlarge`
        display: flex;
        text-align: left;
        align-items: ${$isLoginPage ? 'center' : 'flex-start'};
        `}
  `,
);
