import { useGateValue } from '@statsig/react-bindings';

export enum Flags {
  ADDRESS_VALIDATION_SUGGESTIONS = 'address_validation_suggestions',
  ANNUAL_REPORTS = 'annual_reports',
}

export const useFlag = (key: Flags): boolean => {
  return useGateValue(key);
};
