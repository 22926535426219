import { useMutation } from '@tanstack/react-query';

import {
  CreatePartnershipSubscriptionResponse,
  createPartnershipSubscription,
} from '../graphql/createPartnershipSubscription';
import { isSupaGQLError } from '../utils/SupaGraphQLError';

const handleSubCreateError = (error: unknown): CreatePartnershipSubscriptionResponse => {
  if (isSupaGQLError(error)) {
    if (error.code === 'SUBSCRIPTION_ALREADY_EXISTS') {
      return {
        createPartnershipSubscription: {},
      };
    }
  }

  throw error;
};

export const useCreatePartnershipSubscription = () =>
  useMutation({
    mutationKey: ['createPartnershipSubscription'],
    mutationFn: () => createPartnershipSubscription().catch(handleSubCreateError),
  });
