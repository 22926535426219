import { forwardRef } from 'react';

import { StyledPasswordRevealIcon } from '../Icons';

import { TextField, TextFieldProps } from './TextField';

interface PasswordFieldProps extends TextFieldProps {
  showIcon?: boolean;
  showPassword: boolean;
  onRevealIconClick: React.MouseEventHandler<SVGSVGElement>;
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ onRevealIconClick, showIcon = true, showPassword, ...props }, ref) => (
    <TextField
      {...props}
      ref={ref}
      icon={showIcon ? 'password' : undefined}
      aria-label="password"
      type={showPassword ? 'text' : 'password'}
    >
      <StyledPasswordRevealIcon onClick={onRevealIconClick} />
    </TextField>
  ),
);
