import { css, styled } from 'styled-components';

import { media } from '../../../themes/signup';

import { INPUT_PADDING } from './InputPadding';

export const Label = styled.label(
  ({ theme }) => css`
    line-height: 150%;
    font-weight: 700;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.grayLightest};
    position: absolute;
    left: ${INPUT_PADDING};
    transition: all 0.2s ease;

    input:focus + &,
    select:focus + &,
    input:not(:placeholder-shown) + &,
    select:valid + &,
    select:has(option:not([value='']):checked) + & {
      transform: translate3d(0, -10px, 0);
      line-height: ${theme.fontSizes.medium};
      font-size: ${theme.fontSizes.xsmall};
    }

    ${media.xlarge`
      font-size: ${theme.fontSizes.medium};

      input:focus + &,
      select:focus + &,
      input:not(:placeholder-shown) + &,
      select:valid + &,
      select:has(option:not([value=""]):checked) + & {
        transform: translate3d(0, -12px, 0);
      }
    `}
  `,
);

export const ErrorLabel = styled.label(
  ({ theme }) => css`
    display: block;
    padding-left: ${INPUT_PADDING};
    padding-top: 4px;
    line-height: 100%;
    font-weight: 700;
    font-size: ${theme.fontSizes.xsmall};
    color: ${theme.colors.errorText};
  `,
);
