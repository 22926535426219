import { ExtendableError } from '../../utils/ExtendableError';

export class CallbackError extends ExtendableError {
  code: string;

  constructor(message: string, code: string, errorOptions?: ErrorOptions) {
    super(message, errorOptions);
    this.code = code;
    this.name = 'CallbackError';
  }
}
