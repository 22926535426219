import { useQuery } from '@tanstack/react-query';

import { membershipOfferings } from '../graphql/membershipOfferings';
import { MembershipOfferings } from '../types';

export const useGetMembershipOfferings = (isAutoFetch = true) =>
  useQuery<MembershipOfferings>({
    queryKey: ['getMembershipOfferings'],
    queryFn: async () => {
      const response = await membershipOfferings();
      return response.membershipOffering;
    },
    retry: false,
    staleTime: Infinity,
    enabled: isAutoFetch,
  });
