/* tslint:disable */
/* eslint-disable */
/**
 * Account API
 * Oura Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountInformationResponse } from '../model';
// @ts-ignore
import { ApiError400Response } from '../model';
// @ts-ignore
import { ApiError401Response } from '../model';
// @ts-ignore
import { ApiError403Response } from '../model';
// @ts-ignore
import { ApiError404Response } from '../model';
// @ts-ignore
import { ApiError406Response } from '../model';
// @ts-ignore
import { ApiError415Response } from '../model';
// @ts-ignore
import { ApiError429Response } from '../model';
// @ts-ignore
import { ApiError500Response } from '../model';
// @ts-ignore
import { AuthenticationResponse } from '../model';
// @ts-ignore
import { ExchangeOneTimeTokenRequest } from '../model';
// @ts-ignore
import { ExchangeOneTimeTokenResponse } from '../model';
// @ts-ignore
import { GetSignupResponse } from '../model';
// @ts-ignore
import { InitLoginRequest } from '../model';
// @ts-ignore
import { InitLoginResponse } from '../model';
// @ts-ignore
import { LoginEndRequest } from '../model';
// @ts-ignore
import { LoginEndResponse } from '../model';
// @ts-ignore
import { LoginPasswordRequestBody } from '../model';
// @ts-ignore
import { LoginResponse } from '../model';
// @ts-ignore
import { LogoutRequestBody } from '../model';
// @ts-ignore
import { LogoutResponse } from '../model';
// @ts-ignore
import { RequestResetPasswordBody } from '../model';
// @ts-ignore
import { ResetPasswordRequestBody } from '../model';
// @ts-ignore
import { ResetPasswordResponseBody } from '../model';
// @ts-ignore
import { SignupInstructionsRequestBody } from '../model';
// @ts-ignore
import { SignupInstructionsResponse } from '../model';
// @ts-ignore
import { SignupRequestBody } from '../model';
// @ts-ignore
import { SignupResponse } from '../model';
// @ts-ignore
import { SignupStatusRequest } from '../model';
// @ts-ignore
import { SignupStatusResponse } from '../model';
// @ts-ignore
import { UpdateEmailRequestBody } from '../model';
// @ts-ignore
import { UpdatePasswordRequestBody } from '../model';
// @ts-ignore
import { VerifyEmailRequestBody } from '../model';
// @ts-ignore
import { VerifyEmailResponseBody } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get account information
         * @summary Get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finish login flow with MOI
         * @summary Finish login flow with MOI
         * @param {LoginEndRequest} [loginEndRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endLogin: async (loginEndRequest?: LoginEndRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/login/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginEndRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for getting JWT tokens with onetime token
         * @summary Exchanges one-time token for JWT tokens to login user
         * @param {ExchangeOneTimeTokenRequest} [exchangeOneTimeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeOneTimeToken: async (exchangeOneTimeTokenRequest?: ExchangeOneTimeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeOneTimeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download annual report by year
         * @summary Download annual report
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualReport: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getAnnualReport', 'year', year)
            const localVarPath = `/account/annual-report/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAccessAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download invoice by id
         * @summary Download invoice
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: async (invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/account/invoice/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAccessAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get email by signup token
         * @summary Validate sign up token and get email address
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignup: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getSignup', 'token', token)
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for checking the status of the account and signup
         * @summary Check account/signup status by querying Supa
         * @param {SignupStatusRequest} [signupStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignupStatus: async (signupStatusRequest?: SignupStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signup/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiate Login flow with MOI
         * @summary Initiates the login flow with MOI
         * @param {InitLoginRequest} [initLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateLogin: async (initLoginRequest?: InitLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/login/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for logging out user
         * @summary Logout user from membership portal
         * @param {LogoutRequestBody} [logoutRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountLogout: async (logoutRequestBody?: LogoutRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieRefreshAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for logging user in to membership portal with password
         * @summary Login user to membership portal with password
         * @param {LoginPasswordRequestBody} [loginPasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountPasswordLogin: async (loginPasswordRequestBody?: LoginPasswordRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/login/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPasswordRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for validating JWT access token
         * @summary Validate JWT access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJwtAccess: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAccessAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for getting new JWT access token
         * @summary Generate new JWT access and refresh tokens by using the refresh token. Old access token is invalidated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJwtRefresh: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieRefreshAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for creating a new user
         * @summary Create a new user by using the signup token
         * @param {SignupRequestBody} [signupRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignup: async (signupRequestBody?: SignupRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Trigger sending signup instructions email
         * @summary Create signup token by email and optional ring serial number
         * @param {string} userAgent Oura User agent header
         * @param {SignupInstructionsRequestBody} [signupInstructionsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignupInstructions: async (userAgent: string, signupInstructionsRequestBody?: SignupInstructionsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAgent' is not null or undefined
            assertParamExists('postSignupInstructions', 'userAgent', userAgent)
            const localVarPath = `/signup/instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupInstructionsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for requesting password reset
         * @summary Request password reset
         * @param {RequestResetPasswordBody} [requestResetPasswordBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword: async (requestResetPasswordBody?: RequestResetPasswordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/request-reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestResetPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for resetting users password
         * @summary Reset user password
         * @param {ResetPasswordRequestBody} [resetPasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequestBody?: ResetPasswordRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for updating users email
         * @summary Update users email address
         * @param {UpdateEmailRequestBody} [updateEmailRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (updateEmailRequestBody?: UpdateEmailRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAccessAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for updating users password
         * @summary Update users password
         * @param {UpdatePasswordRequestBody} [updatePasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (updatePasswordRequestBody?: UpdatePasswordRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAccessAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint for verifying email update
         * @summary Verify updated email address
         * @param {VerifyEmailRequestBody} [verifyEmailRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (verifyEmailRequestBody?: VerifyEmailRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/email/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Get account information
         * @summary Get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finish login flow with MOI
         * @summary Finish login flow with MOI
         * @param {LoginEndRequest} [loginEndRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endLogin(loginEndRequest?: LoginEndRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginEndResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endLogin(loginEndRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for getting JWT tokens with onetime token
         * @summary Exchanges one-time token for JWT tokens to login user
         * @param {ExchangeOneTimeTokenRequest} [exchangeOneTimeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeOneTimeToken(exchangeOneTimeTokenRequest?: ExchangeOneTimeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeOneTimeTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeOneTimeToken(exchangeOneTimeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download annual report by year
         * @summary Download annual report
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnualReport(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnualReport(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download invoice by id
         * @summary Download invoice
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoice(invoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoice(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get email by signup token
         * @summary Validate sign up token and get email address
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignup(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignup(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for checking the status of the account and signup
         * @summary Check account/signup status by querying Supa
         * @param {SignupStatusRequest} [signupStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignupStatus(signupStatusRequest?: SignupStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignupStatus(signupStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initiate Login flow with MOI
         * @summary Initiates the login flow with MOI
         * @param {InitLoginRequest} [initLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateLogin(initLoginRequest?: InitLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateLogin(initLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for logging out user
         * @summary Logout user from membership portal
         * @param {LogoutRequestBody} [logoutRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountLogout(logoutRequestBody?: LogoutRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountLogout(logoutRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for logging user in to membership portal with password
         * @summary Login user to membership portal with password
         * @param {LoginPasswordRequestBody} [loginPasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountPasswordLogin(loginPasswordRequestBody?: LoginPasswordRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountPasswordLogin(loginPasswordRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for validating JWT access token
         * @summary Validate JWT access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJwtAccess(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJwtAccess(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for getting new JWT access token
         * @summary Generate new JWT access and refresh tokens by using the refresh token. Old access token is invalidated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJwtRefresh(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJwtRefresh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for creating a new user
         * @summary Create a new user by using the signup token
         * @param {SignupRequestBody} [signupRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignup(signupRequestBody?: SignupRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignup(signupRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Trigger sending signup instructions email
         * @summary Create signup token by email and optional ring serial number
         * @param {string} userAgent Oura User agent header
         * @param {SignupInstructionsRequestBody} [signupInstructionsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignupInstructions(userAgent: string, signupInstructionsRequestBody?: SignupInstructionsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupInstructionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignupInstructions(userAgent, signupInstructionsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for requesting password reset
         * @summary Request password reset
         * @param {RequestResetPasswordBody} [requestResetPasswordBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPassword(requestResetPasswordBody?: RequestResetPasswordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPassword(requestResetPasswordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for resetting users password
         * @summary Reset user password
         * @param {ResetPasswordRequestBody} [resetPasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequestBody?: ResetPasswordRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for updating users email
         * @summary Update users email address
         * @param {UpdateEmailRequestBody} [updateEmailRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(updateEmailRequestBody?: UpdateEmailRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(updateEmailRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for updating users password
         * @summary Update users password
         * @param {UpdatePasswordRequestBody} [updatePasswordRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(updatePasswordRequestBody?: UpdatePasswordRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(updatePasswordRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint for verifying email update
         * @summary Verify updated email address
         * @param {VerifyEmailRequestBody} [verifyEmailRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(verifyEmailRequestBody?: VerifyEmailRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEmailResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(verifyEmailRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * Get account information
         * @summary Get account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe(options?: AxiosRequestConfig): AxiosPromise<AccountInformationResponse> {
            return localVarFp.accountMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Finish login flow with MOI
         * @summary Finish login flow with MOI
         * @param {AccountApiEndLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endLogin(requestParameters: AccountApiEndLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LoginEndResponse> {
            return localVarFp.endLogin(requestParameters.loginEndRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for getting JWT tokens with onetime token
         * @summary Exchanges one-time token for JWT tokens to login user
         * @param {AccountApiExchangeOneTimeTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeOneTimeToken(requestParameters: AccountApiExchangeOneTimeTokenRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ExchangeOneTimeTokenResponse> {
            return localVarFp.exchangeOneTimeToken(requestParameters.exchangeOneTimeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Download annual report by year
         * @summary Download annual report
         * @param {AccountApiGetAnnualReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualReport(requestParameters: AccountApiGetAnnualReportRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getAnnualReport(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * Download invoice by id
         * @summary Download invoice
         * @param {AccountApiGetInvoiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice(requestParameters: AccountApiGetInvoiceRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getInvoice(requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get email by signup token
         * @summary Validate sign up token and get email address
         * @param {AccountApiGetSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignup(requestParameters: AccountApiGetSignupRequest, options?: AxiosRequestConfig): AxiosPromise<GetSignupResponse> {
            return localVarFp.getSignup(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for checking the status of the account and signup
         * @summary Check account/signup status by querying Supa
         * @param {AccountApiGetSignupStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignupStatus(requestParameters: AccountApiGetSignupStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SignupStatusResponse> {
            return localVarFp.getSignupStatus(requestParameters.signupStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiate Login flow with MOI
         * @summary Initiates the login flow with MOI
         * @param {AccountApiInitiateLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateLogin(requestParameters: AccountApiInitiateLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<InitLoginResponse> {
            return localVarFp.initiateLogin(requestParameters.initLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for logging out user
         * @summary Logout user from membership portal
         * @param {AccountApiPostAccountLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountLogout(requestParameters: AccountApiPostAccountLogoutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LogoutResponse> {
            return localVarFp.postAccountLogout(requestParameters.logoutRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for logging user in to membership portal with password
         * @summary Login user to membership portal with password
         * @param {AccountApiPostAccountPasswordLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountPasswordLogin(requestParameters: AccountApiPostAccountPasswordLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.postAccountPasswordLogin(requestParameters.loginPasswordRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for validating JWT access token
         * @summary Validate JWT access token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJwtAccess(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postJwtAccess(options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for getting new JWT access token
         * @summary Generate new JWT access and refresh tokens by using the refresh token. Old access token is invalidated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJwtRefresh(options?: AxiosRequestConfig): AxiosPromise<AuthenticationResponse> {
            return localVarFp.postJwtRefresh(options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for creating a new user
         * @summary Create a new user by using the signup token
         * @param {AccountApiPostSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignup(requestParameters: AccountApiPostSignupRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SignupResponse> {
            return localVarFp.postSignup(requestParameters.signupRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Trigger sending signup instructions email
         * @summary Create signup token by email and optional ring serial number
         * @param {AccountApiPostSignupInstructionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignupInstructions(requestParameters: AccountApiPostSignupInstructionsRequest, options?: AxiosRequestConfig): AxiosPromise<SignupInstructionsResponse> {
            return localVarFp.postSignupInstructions(requestParameters.userAgent, requestParameters.signupInstructionsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for requesting password reset
         * @summary Request password reset
         * @param {AccountApiRequestResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(requestParameters: AccountApiRequestResetPasswordRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.requestResetPassword(requestParameters.requestResetPasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for resetting users password
         * @summary Reset user password
         * @param {AccountApiResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(requestParameters: AccountApiResetPasswordRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResetPasswordResponseBody> {
            return localVarFp.resetPassword(requestParameters.resetPasswordRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for updating users email
         * @summary Update users email address
         * @param {AccountApiUpdateEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(requestParameters: AccountApiUpdateEmailRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateEmail(requestParameters.updateEmailRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for updating users password
         * @summary Update users password
         * @param {AccountApiUpdatePasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(requestParameters: AccountApiUpdatePasswordRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePassword(requestParameters.updatePasswordRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint for verifying email update
         * @summary Verify updated email address
         * @param {AccountApiVerifyEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(requestParameters: AccountApiVerifyEmailRequest = {}, options?: AxiosRequestConfig): AxiosPromise<VerifyEmailResponseBody> {
            return localVarFp.verifyEmail(requestParameters.verifyEmailRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for endLogin operation in AccountApi.
 * @export
 * @interface AccountApiEndLoginRequest
 */
export interface AccountApiEndLoginRequest {
    /**
     * 
     * @type {LoginEndRequest}
     * @memberof AccountApiEndLogin
     */
    readonly loginEndRequest?: LoginEndRequest
}

/**
 * Request parameters for exchangeOneTimeToken operation in AccountApi.
 * @export
 * @interface AccountApiExchangeOneTimeTokenRequest
 */
export interface AccountApiExchangeOneTimeTokenRequest {
    /**
     * 
     * @type {ExchangeOneTimeTokenRequest}
     * @memberof AccountApiExchangeOneTimeToken
     */
    readonly exchangeOneTimeTokenRequest?: ExchangeOneTimeTokenRequest
}

/**
 * Request parameters for getAnnualReport operation in AccountApi.
 * @export
 * @interface AccountApiGetAnnualReportRequest
 */
export interface AccountApiGetAnnualReportRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiGetAnnualReport
     */
    readonly year: number
}

/**
 * Request parameters for getInvoice operation in AccountApi.
 * @export
 * @interface AccountApiGetInvoiceRequest
 */
export interface AccountApiGetInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiGetInvoice
     */
    readonly invoiceId: string
}

/**
 * Request parameters for getSignup operation in AccountApi.
 * @export
 * @interface AccountApiGetSignupRequest
 */
export interface AccountApiGetSignupRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiGetSignup
     */
    readonly token: string
}

/**
 * Request parameters for getSignupStatus operation in AccountApi.
 * @export
 * @interface AccountApiGetSignupStatusRequest
 */
export interface AccountApiGetSignupStatusRequest {
    /**
     * 
     * @type {SignupStatusRequest}
     * @memberof AccountApiGetSignupStatus
     */
    readonly signupStatusRequest?: SignupStatusRequest
}

/**
 * Request parameters for initiateLogin operation in AccountApi.
 * @export
 * @interface AccountApiInitiateLoginRequest
 */
export interface AccountApiInitiateLoginRequest {
    /**
     * 
     * @type {InitLoginRequest}
     * @memberof AccountApiInitiateLogin
     */
    readonly initLoginRequest?: InitLoginRequest
}

/**
 * Request parameters for postAccountLogout operation in AccountApi.
 * @export
 * @interface AccountApiPostAccountLogoutRequest
 */
export interface AccountApiPostAccountLogoutRequest {
    /**
     * 
     * @type {LogoutRequestBody}
     * @memberof AccountApiPostAccountLogout
     */
    readonly logoutRequestBody?: LogoutRequestBody
}

/**
 * Request parameters for postAccountPasswordLogin operation in AccountApi.
 * @export
 * @interface AccountApiPostAccountPasswordLoginRequest
 */
export interface AccountApiPostAccountPasswordLoginRequest {
    /**
     * 
     * @type {LoginPasswordRequestBody}
     * @memberof AccountApiPostAccountPasswordLogin
     */
    readonly loginPasswordRequestBody?: LoginPasswordRequestBody
}

/**
 * Request parameters for postSignup operation in AccountApi.
 * @export
 * @interface AccountApiPostSignupRequest
 */
export interface AccountApiPostSignupRequest {
    /**
     * 
     * @type {SignupRequestBody}
     * @memberof AccountApiPostSignup
     */
    readonly signupRequestBody?: SignupRequestBody
}

/**
 * Request parameters for postSignupInstructions operation in AccountApi.
 * @export
 * @interface AccountApiPostSignupInstructionsRequest
 */
export interface AccountApiPostSignupInstructionsRequest {
    /**
     * Oura User agent header
     * @type {string}
     * @memberof AccountApiPostSignupInstructions
     */
    readonly userAgent: string

    /**
     * 
     * @type {SignupInstructionsRequestBody}
     * @memberof AccountApiPostSignupInstructions
     */
    readonly signupInstructionsRequestBody?: SignupInstructionsRequestBody
}

/**
 * Request parameters for requestResetPassword operation in AccountApi.
 * @export
 * @interface AccountApiRequestResetPasswordRequest
 */
export interface AccountApiRequestResetPasswordRequest {
    /**
     * 
     * @type {RequestResetPasswordBody}
     * @memberof AccountApiRequestResetPassword
     */
    readonly requestResetPasswordBody?: RequestResetPasswordBody
}

/**
 * Request parameters for resetPassword operation in AccountApi.
 * @export
 * @interface AccountApiResetPasswordRequest
 */
export interface AccountApiResetPasswordRequest {
    /**
     * 
     * @type {ResetPasswordRequestBody}
     * @memberof AccountApiResetPassword
     */
    readonly resetPasswordRequestBody?: ResetPasswordRequestBody
}

/**
 * Request parameters for updateEmail operation in AccountApi.
 * @export
 * @interface AccountApiUpdateEmailRequest
 */
export interface AccountApiUpdateEmailRequest {
    /**
     * 
     * @type {UpdateEmailRequestBody}
     * @memberof AccountApiUpdateEmail
     */
    readonly updateEmailRequestBody?: UpdateEmailRequestBody
}

/**
 * Request parameters for updatePassword operation in AccountApi.
 * @export
 * @interface AccountApiUpdatePasswordRequest
 */
export interface AccountApiUpdatePasswordRequest {
    /**
     * 
     * @type {UpdatePasswordRequestBody}
     * @memberof AccountApiUpdatePassword
     */
    readonly updatePasswordRequestBody?: UpdatePasswordRequestBody
}

/**
 * Request parameters for verifyEmail operation in AccountApi.
 * @export
 * @interface AccountApiVerifyEmailRequest
 */
export interface AccountApiVerifyEmailRequest {
    /**
     * 
     * @type {VerifyEmailRequestBody}
     * @memberof AccountApiVerifyEmail
     */
    readonly verifyEmailRequestBody?: VerifyEmailRequestBody
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * Get account information
     * @summary Get account information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountMe(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finish login flow with MOI
     * @summary Finish login flow with MOI
     * @param {AccountApiEndLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public endLogin(requestParameters: AccountApiEndLoginRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).endLogin(requestParameters.loginEndRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for getting JWT tokens with onetime token
     * @summary Exchanges one-time token for JWT tokens to login user
     * @param {AccountApiExchangeOneTimeTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public exchangeOneTimeToken(requestParameters: AccountApiExchangeOneTimeTokenRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).exchangeOneTimeToken(requestParameters.exchangeOneTimeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download annual report by year
     * @summary Download annual report
     * @param {AccountApiGetAnnualReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAnnualReport(requestParameters: AccountApiGetAnnualReportRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAnnualReport(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download invoice by id
     * @summary Download invoice
     * @param {AccountApiGetInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getInvoice(requestParameters: AccountApiGetInvoiceRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getInvoice(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get email by signup token
     * @summary Validate sign up token and get email address
     * @param {AccountApiGetSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSignup(requestParameters: AccountApiGetSignupRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getSignup(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for checking the status of the account and signup
     * @summary Check account/signup status by querying Supa
     * @param {AccountApiGetSignupStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSignupStatus(requestParameters: AccountApiGetSignupStatusRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getSignupStatus(requestParameters.signupStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiate Login flow with MOI
     * @summary Initiates the login flow with MOI
     * @param {AccountApiInitiateLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public initiateLogin(requestParameters: AccountApiInitiateLoginRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).initiateLogin(requestParameters.initLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for logging out user
     * @summary Logout user from membership portal
     * @param {AccountApiPostAccountLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postAccountLogout(requestParameters: AccountApiPostAccountLogoutRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postAccountLogout(requestParameters.logoutRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for logging user in to membership portal with password
     * @summary Login user to membership portal with password
     * @param {AccountApiPostAccountPasswordLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postAccountPasswordLogin(requestParameters: AccountApiPostAccountPasswordLoginRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postAccountPasswordLogin(requestParameters.loginPasswordRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for validating JWT access token
     * @summary Validate JWT access token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postJwtAccess(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postJwtAccess(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for getting new JWT access token
     * @summary Generate new JWT access and refresh tokens by using the refresh token. Old access token is invalidated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postJwtRefresh(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postJwtRefresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for creating a new user
     * @summary Create a new user by using the signup token
     * @param {AccountApiPostSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postSignup(requestParameters: AccountApiPostSignupRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postSignup(requestParameters.signupRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Trigger sending signup instructions email
     * @summary Create signup token by email and optional ring serial number
     * @param {AccountApiPostSignupInstructionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postSignupInstructions(requestParameters: AccountApiPostSignupInstructionsRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postSignupInstructions(requestParameters.userAgent, requestParameters.signupInstructionsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for requesting password reset
     * @summary Request password reset
     * @param {AccountApiRequestResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestResetPassword(requestParameters: AccountApiRequestResetPasswordRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).requestResetPassword(requestParameters.requestResetPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for resetting users password
     * @summary Reset user password
     * @param {AccountApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resetPassword(requestParameters: AccountApiResetPasswordRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).resetPassword(requestParameters.resetPasswordRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for updating users email
     * @summary Update users email address
     * @param {AccountApiUpdateEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateEmail(requestParameters: AccountApiUpdateEmailRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateEmail(requestParameters.updateEmailRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for updating users password
     * @summary Update users password
     * @param {AccountApiUpdatePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updatePassword(requestParameters: AccountApiUpdatePasswordRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updatePassword(requestParameters.updatePasswordRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint for verifying email update
     * @summary Verify updated email address
     * @param {AccountApiVerifyEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public verifyEmail(requestParameters: AccountApiVerifyEmailRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).verifyEmail(requestParameters.verifyEmailRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
